$brand-colour: #323539;
$gradient-end: #000000;
$title-fonts: "DINNext","DIN Alternate","Helvetica Neue",Arial,sans-serif;
$body-fonts: "Helvetica Neue",Arial,sans-serif;

html, body {
  background-color: #f6f6f6
}

main > section {
  padding: 40px;
  &:first-child { background-color: #fff; }
}


header, nav {
  text-align: center;
  background: $brand-colour;
  background: -moz-linear-gradient(top, $brand-colour 0%, $gradient-end 100%);
  background: -webkit-linear-gradient(top, $brand-colour 0%, $gradient-end 100%);
  background: linear-gradient(to bottom, $brand-colour 0%, $gradient-end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323539', endColorstr='#000000',GradientType=0 );

  padding: 10px 0;
  a, a:hover, a:active {
    color: #fff;
    text-decoration: none;
  }
  h1 {
    @media (max-width: 380px) {
      & { font-size: 2rem !important; }
      small { font-size: 1.3rem !important; }
    }
    @media (min-width: 381px) and (max-width: 520px) {
      & { font-size: 2.5rem !important; }
      small { font-size: 1.6rem !important; }
    }
  }
}
nav {
  background: $brand-colour;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $title-fonts;
  text-transform: uppercase;
  a {
    display: block;
    margin: 0 15px;
    &, &:hover { color: #fff; }
    &:active { color: #ccc; }
    @media (max-width: 380px) {
      &#fundraiser { display: none; }
    }
  }
  #number {
    position: absolute;
    right: calc((100vw - 960px) / 2);
    @media (max-width: 992px) {
      & { display: none; }
    }
    @media (min-width: 1200px) {
      right: calc((100vw - 1140px) / 2);
    }
  }
}

article {
  padding: 40px 0;
  &:not(:last-child) { border-bottom: 1px solid #ddd; }
  small {
    display: block;
    margin-top: 10px;
  }
}

h1, h2, h3, h4 {
  header & {
    margin: 10px 0;
    line-height: 1 !important;
    small {
      display: block;
      font-size: 2.2rem;
    }
  }
  font-family: $title-fonts;
  text-transform: uppercase;
  sup {
    font-family: $body-fonts;
    font-size: 0.3em;
    top: -1.5em;
    font-weight: 100;
  }
}

.image-gallery {
  overflow: auto;
  margin-left: -1% !important;
  display: flex;
  align-items: baseline;
  a {
    display: block;
    margin: 0 0 1% 1%;
    width: 100px;
    text-align: center;
    text-decoration: none !important;
    span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 3px 0;
      font-size: 0.75em;
      color: #666;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}

a.button {
  display: inline-block;
  padding: 10px 13px 7px;
  margin-left: 10px;
  color: #fff;
  background: #000;
  border-radius: 3px;
  font-family: $title-fonts;
  font-size: 1.2em;
  text-transform: uppercase;
  &:hover, &:active {
    color: #fff;
    background: lighten(#000, 30%);
    text-decoration: none;
  }
}

form#donate {
  text-align: center;
  margin: 0 auto;
}

.twitter-hashtag-button {
  display: block;
  margin: 0 auto;
}

footer {
  font-size: 0.8em;
  color: #999;
  text-align: center;
  margin: 40px 0;
  section#info {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    p {
      width: 40%;
      flex-grow: 2;
      @media (min-width: 769px) and (max-width: 992px) {
        width: 60%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      &.words {
        order: 1;
        @media (max-width: 768px) {
          order: 0 !important;
        }
      }
      &.logo {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        opacity: 0.3;
        width: 30%;
        @media (min-width: 769px) and (max-width: 992px) {
          width: 15%;
        }
        @media (max-width: 768px) {
          width: 40%;
          order: 1 !important;
          min-height: 75px;
        }
        flex-grow: 1;
      }
      &#c_aquila {
        background-image: url('/assets/c_aquila.svg');
        order: 0;
      }
      &#fringe {
        background-image: url('/assets/fringe.svg');
        order: 2;
      }
    }
  }
}
